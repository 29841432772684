import React from 'react'
import { Link } from 'react-router-dom'
import "./style.css"

const ModuleCard = (props) => {
  return (
    <div className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-12">
      <div className="sidebar-link">
        <ul className="coaching-service-nav-menu">
          <li className="active2">
            <Link to={props.link}>
              <div className="featured-icon-box icon-align-top-content style14">
                <div className="featured-icon">
                  <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-skincolor prt-icon_element-size-md">
                    <div className={`flaticon-image ${props.iconClass}`} />
                  </div>
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>{props.title}</h3>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ModuleCard