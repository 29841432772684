// @ts-nocheck
import { Snackbar, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from "react-redux"
import { axiosActions } from "rtk/slices/axios-slice"

export const UpdatingSnackBar = () => {

  const dispatch = useDispatch()
  const { setSending, setSuccess, setFailure } = axiosActions
  const isSending = useSelector(state => state.axios.isSending)
  const isSuccess = useSelector(state => state.axios.isSuccess)
  const isFailure = useSelector(state => state.axios.isFailure)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return false
    }

    dispatch(setSending(false))
    dispatch(setSuccess(false))
    dispatch(setFailure(false))
  }

  const message = () => {
    let msg
    if (isSuccess) {
      msg = "Database updated..."
    }
    else if (isFailure) {
      msg = "Error while updating data..."
    }
    else {
      msg = "Updating data please wait..."
    }

    return msg
  }

  const actions = (
    <>
      <IconButton
        size="medium"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{
          marginX: 1,
          "&:hover": {
            backgroundColor: "#585266"
          }
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  )

  return (
    <Snackbar
      open={isSending}
      onClose={handleClose}
      autoHideDuration={(isSuccess || isFailure) ? 4000 : undefined}
      message={message()}
      action={(isSuccess || isFailure) ? actions : undefined}
      sx={{
        marginX: 5,
      }}
    />
  )
}