import http from "core/auth-http";
import handlApiError from "core/handleApiError";
import { createContext, useContext, useState } from "react";

const StateContext = createContext({
  apiData: {},
  data: [],
  onSubmit: formData => { },
  initialData: () => { },
  apiErrors: {}, isLoading: true,
  errorModalOpen: false, setErrorModalOpen: status => { },
  filterModalOpen: false, setFilterModalOpen: status => { },
})

export const ContextProvider = ({ children }) => {

  const [apiData, setApiData] = useState({})
  const [data, setData] = useState([])
  const [apiErrors, setApiErrors] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const initialData = () => {
    return http.get('/finance/cash-payment')
      .then(res => {
        const { ledgers, currencies, data } = res.data
        setData(data)
        setApiData({
          ...apiData,
          ledgers: ledgers,
          currencies: currencies,
        })
      })
      .catch(error => {
        setErrorModalOpen(true)
        setApiErrors(handlApiError(error))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSubmit = formData => {
    setIsLoading(true)
    setData([])
    http.get('/finance/cash-payment', { params: formData })
      .then(res => {
        const { data } = res.data
        setData(data)
      })
      .catch(error => {
        setErrorModalOpen(true)
        setApiErrors(handlApiError(error))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <StateContext.Provider value={{
      isLoading, initialData, apiData, data, apiErrors,
      onSubmit,
      errorModalOpen, setErrorModalOpen,
      filterModalOpen, setFilterModalOpen,
    }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
