// @ts-nocheck
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from "react-redux"
import { axiosActions } from "rtk/slices/axios-slice"

const ErrorModal = () => {

  const dispatch = useDispatch()
  const { setSending, setSuccess, setFailure, setShowErrowModal } = axiosActions
  const isShowErrowModal = useSelector(state => state.axios.isShowErrowModal)
  const apiErrors = useSelector(state => state.axios.errors)

  const handleClose = () => {
    dispatch(setShowErrowModal(false))
    dispatch(setSending(false))
    dispatch(setSuccess(false))
    dispatch(setFailure(false))
  }

  return (
    <Dialog
      open={isShowErrowModal}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
        },
      }}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Error
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 2 }} dividers={false}>
        {Object.keys(apiErrors).length === 0 ? undefined : (
          apiErrors['errors'].map((error, index) => {
            return (<p key={index}>- {error}</p>)
          })
        )}
      </DialogContent>
      <DialogActions sx={{ paddingX: 2 }}>
        <Button color="primary" onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog >
  );
}

export default ErrorModal