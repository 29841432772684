import { Box, Stack, Typography } from "@mui/material"
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import { Link } from "react-router-dom"
import { formatAmount } from "core/functions"

export const Columns = [
  {
    name: '',
    cell: row => (
      <Stack className="action-container" direction="row" spacing={2} sx={{ opacity: 0 }}>
        <Link to={`/finance/cash_payment/${row.id}/edit`} target="_blank">
          <PrintOutlinedIcon sx={{ fontSize: 20 }} />
        </Link>
        <Link to={`/finance/cash_payment/${row.id}/edit`} target="_blank">
          <EditNoteOutlinedIcon sx={{ fontSize: 30 }} />
        </Link>
      </Stack>
    ),
  },
  {
    name: "Ser",
    selector: row => row.ser,
    sortable: true,
    width: "80px",
    wrap: false,
    sortDirection: 'asc'
  },
  {
    name: "Date",
    selector: row => row.date,
    sortable: true,
    width: "120px",
  },
  {
    name: "Receipt",
    selector: row => row.receipt,
    sortable: true
  },
  {
    name: "Debit ledger",
    selector: row => row.debit_ledger.en_name,
    sortable: true,
    width: "260px",
  },
  {
    name: "Credit ledger",
    selector: row => row.credit_ledger.en_name,
    sortable: true,
    width: "260px",
  },
  {
    name: "Amount",
    selector: row => row.amount,
    sortable: true,
    width: "120px",
    cell: row => (
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" sx={{ fontSize: '14px' }}>{formatAmount(row.amount)}</Typography>
        <Typography variant="body2" sx={{ fontSize: '13px' }}>{row.currency.en_name}</Typography>
      </Box>
    ),
  },
  {
    name: "Description",
    selector: row => row.notes,
    sortable: true,
    width: "300px",
    wrap: true,
  },
]