import { Button } from '@mui/material'
import PrintIcon from "@mui/icons-material/Print";
import { styled } from '@mui/material/styles';

const ButtonStyled = styled(Button)({
  padding: "4px 22px",
  borderRadius: "18px",
  border: "1px solid #747775",
  color: "#666",
  ":hover": {
    backgroundColor: "#F2F2F2",
    border: "1px solid #747775",
  }
});

const PrintButton = () => {
  return (
    <ButtonStyled
      size='small'
      startIcon={<PrintIcon />}
    >
      Print
    </ButtonStyled>
  )
}

export default PrintButton