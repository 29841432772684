export const Footer = () => {
  return (
    <footer className="footer widget-footer clearfix">
      <div className="second-footer prt-bgimage-yes bg-footer prt-bg bg-base-dark">
        <div className="prt-row-wrapper-bg-layer prt-bg-layer" />
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 widget-area">
              <div className="widget">
                <div className="widgte-text">
                  <div className="widget-title">
                    <h3>Imagine A Better Future</h3>
                  </div>
                  <p>
                    The Most Eminent Visas &amp; Immigration Consultant service
                    provider.
                  </p>
                </div>
                <div className="widget_nav_menu">
                  <div className="widget-title">
                    <h3>Quick Links</h3>
                  </div>
                  <ul className="menu-footer-quick-links">
                    <li>
                      <a href="services-2.html">
                        <i className="fa fa-plus" />
                        business
                      </a>
                    </li>
                    <li>
                      <a href="services-1.html">
                        <i className="fa fa-plus" />
                        Migrate
                      </a>
                    </li>
                    <li>
                      <a href="services-2.html">
                        <i className="fa fa-plus" />
                        Countrie
                      </a>
                    </li>
                    <li>
                      <a href="services-1.html">
                        <i className="fa fa-plus" />
                        Evaluation
                      </a>
                    </li>
                    <li className="last-link">
                      <a href="services-2.html">
                        <i className="fa fa-plus" />
                        Application
                      </a>
                    </li>
                    <li className="last-link">
                      <a href="services-1.html">
                        <i className="fa fa-plus" />
                        Classes
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="widget_social">
                  <div className="social-icons social-hover">
                    <ul className="social-icons d-flex">
                      <li>
                        <a
                          className="prt-social-instagram"
                          href="https://www.instagram.com/theme_tech_mount/"
                          aria-label="instagram"
                        >
                          <i className="icon-instagram" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="prt-social-facebook"
                          href="https://www.facebook.com/themetechMount18"
                          rel="noopener"
                          aria-label="facebook"
                        >
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="prt-social-twitter"
                          href="https://twitter.com/themetechmount"
                          rel="noopener"
                          aria-label="twitter"
                        >
                          <i className="icon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          className="prt-social-linkedin"
                          href="https://www.linkedin.com/company/themetech-mount/"
                          rel="noopener"
                          aria-label="google"
                        >
                          <i className="icon-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 widget-area">
              <div className="widget">
                <div className="enhanced-text-widget en-1">
                  <div className="widget-title">
                    <h3>
                      100+ Best Universities Scholarship Programs From 20 Countries
                    </h3>
                  </div>
                  <p>
                    We also help with other family based employment ased and
                    investment based Immigration.
                    <strong>(Validity From: 2022-01-11 12:00)</strong>
                  </p>
                  <div className="prt-advertiser">
                    <a href="australia.html">
                      <img src="/assets/images/flag-1.png" alt="image1" />
                    </a>
                    <a href="united-kingdom.html">
                      <img src="/assets/images/flag-2.png" alt="image2" />
                    </a>
                    <a href="russia.html">
                      <img src="/assets/images/flag-3.png" alt="image3" />
                    </a>
                    <a href="france.html">
                      <img src="/assets/images/flag-4.png" alt="image4" />
                    </a>
                  </div>
                  <div className="">
                    <a
                      className="prt-btn prt-btn-size-md prt-btn-color-white btn-inline mt-30"
                      href="about-us-2.html"
                    >
                      {" "}
                      Apply Visa Now!{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 widget-area">
              <div className="widget">
                <div className="enhanced-text-widget">
                  <div className="widget-title">
                    <h3>Get In Touch</h3>
                  </div>
                  <div className="res-767-pb-18">
                    <p>141, First Floor, 12 St Roots Terrace, Los Angeles 90010.</p>
                    <p>
                      Front Desk:{" "}
                      <strong>
                        <a href="tel:+56416546546464">+1-89-636-48018</a>
                      </strong>
                    </p>
                    <p>
                      Email:{" "}
                      <strong>
                        <a href="mailto:info@yourdomain.com">info@yourdomain.com</a>
                      </strong>
                    </p>
                    <a
                      className="prt-btn prt-btn-size-md fw-400 prt-btn-color-white btn-inline mt-25"
                      href="about-us-2.html"
                    >
                      {" "}
                      view all branches{" "}
                    </a>
                  </div>
                </div>
                <div className="widget-form">
                  <div className="widget-title">
                    <h3 className="widget-title">Sign up to Latest Updates</h3>
                  </div>
                  <form
                    id="subscribe-form"
                    className="newsletter-form"
                    method="post"
                    action="gre-coaching.html#"
                    data-mailchimp="true"
                  >
                    <div
                      className="mailchimp-inputbox clearfix"
                      id="subscribe-content"
                    >
                      <p>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter Your email address.."
                          required
                        />
                      </p>
                      <button className="submit" type="submit">
                        <i className="fa fa-paper-plane" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 widget-area">
              <div className="visa-consultation">
                <div className="section-title">
                  <div className="featured-title">
                    <h3>Canada</h3>
                  </div>
                  <div className="big-heading">
                    <h3>immigrations</h3>
                  </div>
                </div>
                <div className="description">
                  <h3>Give Wings to Your Dream</h3>
                  <p>
                    Call Us On: <a href="tel:+123-456-7890"> +123-456-7890</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-text prt-bg copyright">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-left">
                <span className="cpy-text">
                  {" "}
                  Copyright © 2023{" "}
                  <a href="index.html" className=" font-weight-500">
                    {" "}
                    AccSys{" "}
                  </a>{" "}
                  Designed by AccSys All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer