import {
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { SaveButton } from "components/tools/index"
import RefreshIcon from '@mui/icons-material/Sync'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useState } from "react"
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from "react-router-dom"

const RefreshPage = () => {
  const navigate = useNavigate()
  return () => navigate(0)
}

const NavigateToSearch = () => {
  const navigate = useNavigate()
  return () => navigate('/finance/cash_payment')
}

const ThreeDotsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Tooltip title="More">
        <IconButton
          size="small"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem dense onClick={NavigateToSearch()}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText>Back to search</ListItemText>
        </MenuItem>

        <MenuItem dense onClick={RefreshPage()}>
          <ListItemIcon>
            <RefreshIcon />
          </ListItemIcon>
          <ListItemText>Refresh page</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

const Buttons = () => {

  return (
    <Stack direction="row-reverse" justifyContent="space-between"
      mb={3} p={2}
    // sx={{ backgroundColor: "#F0F5FB", border: "1px solid #c9d4d7", borderRadius: "10px" }}
    >
      <SaveButton />
      <Stack direction="row" justifyContent="center" spacing={1}>
        <ThreeDotsMenu />

        <Tooltip title="Back to search">
          <IconButton size="small" onClick={NavigateToSearch()}>
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Refresh page">
          <IconButton size="small" onClick={RefreshPage()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default Buttons