/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import DataTable from 'react-data-table-component'
import Card from '@mui/material/Card'
import "./css.css"
import Tools from './Tools'
import { ContextProvider, useStateContext } from './ContextProvider'
import SearchInput from './SearchInput'

const Table = props => {
  return (
    <ContextProvider>
      <Page {...props} />
    </ContextProvider>
  )
}
const Page = props => {

  const { setData, setFilter, setTitle, setOnFilterClick, filter, showSearch } = useStateContext()

  useEffect(() => {

    const data = props.data;
    data.forEach((item, index) => {
      item.ser = index + 1
    })

    setTitle(props.title)
    setData(data)
    setFilter(data)
    setOnFilterClick(props.onFilterClick)
  }, [props.data])

  return (

    <Card className="react-dt" variant="outlined" >
      {showSearch ? <SearchInput /> : <Tools />}
      <DataTable
        columns={props.columns}
        data={filter}
        paginationRowsPerPageOptions={[10, 15, 20]}
        paginationPerPage={10}
        fixedHeader
        selectableRowsHighlight
        pagination={true}
        defaultSortFieldId={props.sortField}
      // selectableRows
      />
    </Card>
  )
}

export default Table