import { createBrowserRouter } from 'react-router-dom'
// Auth pages
import SignUp from 'pages/auth/SignUp'
import SignIn from 'pages/auth/SignIn'
import ResetPassword from 'pages/auth/passwords/Reset'
// Home page
import HomePage from "pages/home/HomePage"
// Dashbardes
import FinanceDashboard from 'pages/finance/dashboard/FinanceDashboard'
// Finance module
import CashPaymentIndex from 'pages/finance/cash-payment/show'
import CashPaymentCreate from 'pages/finance/cash-payment/create'
import CashPaymentEdit from 'pages/finance/cash-payment/edit'
// Error
import Error404 from 'pages/error/404'
import DefaultLayout from './DefaultLayout'
import GuestLayout from './GuestLayout'


const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '*', element: <Error404 /> },
      { path: '/password/reset', element: <ResetPassword /> },

      { path: '/finance', element: <FinanceDashboard /> },
      { path: '/finance/cash_payment/create', element: <CashPaymentCreate /> },
      { path: '/finance/cash_payment', element: <CashPaymentIndex /> },
      { path: '/finance/cash_payment/:id/edit', element: <CashPaymentEdit /> },
    ]
  },
  {
    path: '/',
    element: <GuestLayout />,
    children: [
      { path: '/login', element: <SignIn /> },
      { path: '/register', element: <SignUp /> },
      { path: '*', element: <Error404 /> },
    ]
  },

])

export default appRouter