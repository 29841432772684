import * as yup from "yup"

export const schema = yup.object().shape({
  date:
    yup.string()
      .notOneOf(["Invalid Date"], 'Enter valid date'),
  receipt:
    yup.string()
      .required("Receipt field is equired"),
  reference_number:
    yup.string()
      .nullable(),
  notes:
    yup.string()
      .required("Description is required"),
  debit_ledger:
    yup.number()
      .required('Debit ledger is required'),
  credit_ledger:
    yup.number()
      .required('Credit ledger is required'),
  currency_id:
    yup.number()
      .required("Currency is required"),
  amount:
    yup.number()
      .typeError('Amount is required')
      .required("Amount is required"),
  rate:
    yup.number()
      .required('Rate is required')
      .test('fun-name', "Rate not valid", function (rate) {
        const currency_id = this.parent.currency_id
        return (rate === 1 && currency_id === 1) || (rate > 1 && currency_id > 1)
      })
})