import { Stack, Typography } from '@mui/material'
// import ExportButton from 'components/tools/buttons/ExportToExcel'
import FilterListIcon from '@mui/icons-material/FilterList'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { useStateContext } from './ContextProvider'

const Tools = () => {
  const { setShowSearch, onFilterClick, title } = useStateContext()

  return (
    <Stack direction="row" sx={{
      justifyContent: "space-between",
      p: 2,
      width: "100%",
      alignItems: 'center',
      borderBottom: "1px solid #ddd"
    }}>
      <Typography fontWeight="bold" variant="h6" sx={{
        color: "#364a63",
        letterSpacing: 1
      }}>
        {title}
      </Typography>
      <Stack spacing={2} direction="row" sx={{
        justifyContent: "space-between",
        alignItems: 'center',
        cursor: "pointer",
        color: 'text.secondary',
        '& svg': {
          fontSize: "21px"
        }
      }}>
        <SearchOutlinedIcon onClick={() => setShowSearch(true)} />
        <hr style={{ alignSelf: "stretch", width: 1, height: "auto" }} />
        <FilterListIcon onClick={() => onFilterClick()} />
        <SettingsOutlinedIcon />
      </Stack>
    </Stack>
  )
}

export default Tools