import { Navigate, Outlet } from 'react-router-dom'

const DefaultLayout = () => {

  const token = localStorage.getItem('token')
  if (token === null) {
    return <Navigate to="/login" />
  }

  // console.log(process.env.REACT_APP_API_URL, process.env.PUBLIC_URL + " ll");

  return <Outlet />
}

export default DefaultLayout