import { LinearProgress } from '@mui/material'

const LinerLoading = ({ isOpen, type = "fixed" }) => {
  return !isOpen ? undefined : (
    <LinearProgress
      color="primary"
      sx={{
        position: type,
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1000
      }} />
  )
}

export default LinerLoading