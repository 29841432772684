// @ts-nocheck
import { Snackbar, IconButton, Link as MuiLink } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from "react-redux"
import { axiosActions } from "rtk/slices/axios-slice"

export const CreationSnackBar = () => {

  const dispatch = useDispatch()
  const { setSending, setSuccess, setFailure } = axiosActions
  const isSending = useSelector(state => state.axios.isSending)
  const isSuccess = useSelector(state => state.axios.isSuccess)
  const isFailure = useSelector(state => state.axios.isFailure)
  const editLink = useSelector(state => state.axios.editLink)
  const printLink = useSelector(state => state.axios.printLink)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return false
    }

    dispatch(setSending(false))
    dispatch(setSuccess(false))
    dispatch(setFailure(false))
  }

  const message = () => {
    let msg
    if (isSuccess) {
      msg = "Database updated..."
    }
    else if (isFailure) {
      msg = "Error while sending data..."
    }
    else {
      msg = "Sending data please wait..."
    }

    return msg
  }

  const style = {
    fontWeight: "bold",
    fontSize: "13px !important",
    color: "#8dacda",
    fontFamily: "tahoma",
    letterSpacing: "1px",
    padding: 1,
    textDecoration: 'none',
    marginX: "1px",
    marginY: "2px",
    borderRadius: '5px',
    "&:hover": {
      backgroundColor: "#585266",
      color: "#8ab4f8",
    }
  }

  const actions = (
    <>
      <MuiLink href={editLink} target="_blank" sx={style}>
        View document
      </MuiLink>
      <MuiLink href={printLink} target="_blank" sx={style}>
        Print
      </MuiLink>
      <IconButton
        size="medium"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{
          marginX: 1,
          "&:hover": {
            backgroundColor: "#585266"
          }
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  )

  return (
    <Snackbar
      open={isSending}
      onClose={handleClose}
      autoHideDuration={(isSuccess || isFailure) ? 9000 : undefined}
      message={message()}
      action={isSuccess ? actions : undefined}
      sx={{
        marginX: 5,
      }}
    />
  )
}