import { FormControl } from '@mui/material'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

const Date = ({ label, name, form, internalLabel = false, ...attr }) => {

  const { setValue, getValues, formState, register, trigger } = form
  const { errors } = formState
  const format = "YYYY-MM-DD"

  const handleChange = date => {
    setValue(name, dayjs(date).format(format))
    trigger(name)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth margin="normal">
        {!internalLabel ? <label>{label}</label> : undefined}
        <MobileDatePicker
          {...attr}
          label={internalLabel ? label : undefined}
          format={format}
          value={dayjs(getValues(name))}
          {...register(name)}
          onChange={date => handleChange(date)}
          closeOnSelect
          slotProps={{
            textField: {
              error: !!errors[name],
              helperText: errors[name]?.message,
            },
            actionBar: {
              actions: ["clear", "today", "cancel"],
            },
          }}
        />
      </FormControl>
    </LocalizationProvider >
  )
}

export default Date