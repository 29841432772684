import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import TextField from '@mui/material/TextField'
import { FormControl, ThemeProvider, createTheme } from '@mui/material'
import { fontNoto } from "core/constants"
import { useEffect, useRef } from 'react'

const NumInput = props => {

  const { label, name, form,
    internalLabel = false,
    allowNegative = false,
  } = props
  const { control, formState: { errors } } = form

  const inputRef = useRef(null)
  useEffect(() => {
    if (errors[name]) {
      inputRef.current.focus()
    }
  }, [errors, name])

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontFamily: fontNoto,
              fontSize: 15,
            },
            "&:focus": {
              borderColor: "red",
            },
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Controller
        name={name}
        control={control}
        defaultValue={""}
        render={({ field: { onChange, onBlur, ref, value } }) => (
          <FormControl fullWidth margin="normal">
            {!internalLabel ? <label>{label}</label> : undefined}
            <NumericFormat
              getInputRef={ref}
              value={value}
              thousandSeparator
              allowNegative={allowNegative}
              onValueChange={({ floatValue }) => {
                onChange(floatValue === undefined ? null : floatValue)
              }}
              customInput={TextField}
              inputRef={inputRef}
              label={internalLabel ? label : undefined}
              error={!!errors[name]}
              helperText={errors[name]?.message}
            />
          </FormControl>
        )}
      />
    </ThemeProvider>
  )
}

export default NumInput