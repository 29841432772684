/* eslint-disable react-hooks/exhaustive-deps */
import http from "core/auth-http";
import handlApiError from "core/handleApiError";
import { createContext, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import { schema } from "./form-object"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from "react-redux"
import { axiosActions } from "rtk/slices/axios-slice"
import { formatAmount } from "core/functions"
import { useParams } from 'react-router-dom'

const StateContext = createContext({
  form: undefined,
  isInitLoad: true,
  apiData: {},
  onSubmit: formData => { },
})


export const ContextProvider = ({ children }) => {
  const { id } = useParams()
  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(schema)
  })

  const { watch } = form
  const dispatch = useDispatch()
  const [apiData, setApiData] = useState({})
  const [isInitLoad, setIsInitLoad] = useState(true)
  const {
    setSending,
    setSuccess,
    setInitDataLoad,
    setFailure,
    setErrors,
    setShowErrowModal,
  } = axiosActions
  const amount = watch('amount')
  const rate = watch('rate')

  useEffect(() => {
    initialData()
  }, [])

  useEffect(() => {
    calcTotal()
  }, [amount, rate])

  // calc total
  const calcTotal = () => {
    const product = amount * rate
    setApiData({
      ...apiData,
      total: formatAmount(product)
    })
  }

  const initialData = async () => {
    return http.get(`/finance/cash-payment/${id}/edit`)
      .then(res => {
        const { ledgers, currencies, data } = res.data
        setApiData({
          ...apiData,
          ledgers: ledgers,
          currencies: currencies,
          total: formatAmount(data.amount * data.rate),
        })

        form.reset(data)
      })
      .catch(error => {
        dispatch(setFailure(true))
        dispatch(setShowErrowModal(true))
        dispatch(setErrors(handlApiError(error)))
      })
      .finally(() => {
        dispatch(setInitDataLoad(false))
        setIsInitLoad(false)
      })
  }

  const onSubmit = async formData => {
    const { updated_at, updated_by, ...data } = formData

    dispatch(setSending(true))
    http.put(`/finance/cash-payment/${id}`, data)
      .then(res => {
        dispatch(setSuccess(true))
      })
      .catch(error => {
        dispatch(setFailure(true))
        dispatch(setShowErrowModal(true))
        dispatch(setErrors(handlApiError(error)))
      })
  }

  return (
    <StateContext.Provider value={{
      form,
      isInitLoad,
      apiData,
      onSubmit,
    }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
