import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

const buildNav = (nav, activeNav) => {
  var key1 = 0;
  var key2 = 0;
  return (
    <ul className="menu">
      {nav.map(item => {
        return (
          <li key={++key1}
            className={activeNav.main === item.text ? "mega-menu-item active" : "mega-menu-item"}>
            <Link
              className="mega-menu-link"
              to={item.link}
              onClick={e => handleSubMenuClick(e)}
            >
              {item.text}
            </Link>
            {
              item.subMenu.length === 0 ? null :
                <ul className="mega-submenu">
                  {item.subMenu.map(sub => {
                    return (
                      <li key={++key2}
                        className={activeNav.sub === sub.text ? "active" : null}>
                        <Link to={sub.link}>{sub.text}</Link>
                      </li>
                    )
                  })}
                </ul>
            }
          </li>
        )
      })}
    </ul>
  );
}

const handleSubMenuClick = e => {
  // e.preventDefault();
  e.target.classList.toggle('active');
  if (e.target.nextElementSibling !== null) {
    e.target.nextElementSibling.classList.toggle('active');
  }
}

export const SiteNavbar = ({ nav, activeNav }) => {
  useEffect(() => {
    setMobileMenu(false);
  }, [])

  const [mobileMenu, setMobileMenu] = useState(false)

  const toggleMobileMenu = (e) => {
    setMobileMenu(!mobileMenu);
  }

  return (
    <div id="site-header-menu" className="site-header-menu">
      <div className="site-header-menu-inner prt-stickable-header2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div
                className={
                  "site-navigation d-flex align-items-center justify-content-between"
                  + (mobileMenu === true ? " nav-open" : '')
                }
              >
                {/* site-branding */}
                <div className="site-branding me-auto">
                  <Link
                    className="home-link"
                    to="/"
                    title="AccSys"
                    rel="home"
                  >
                    <img
                      id="logo-img"
                      height={48}
                      width={147}
                      className="img-fluid auto_size"
                      src="/assets/images/accsys4.jpg"
                      // src="/assets/images/logo-img.svg"
                      alt="logo-img"
                    />
                  </Link>
                </div>
                {/* site-branding end */}
                <div onClick={e => toggleMobileMenu(e)}
                  className={
                    "btn-show-menu-mobile menubar menubar--squeeze" + (mobileMenu === true ? " is-active" : '')
                  }
                >
                  <span className="menubar-box">
                    <span className="menubar-inner" />
                  </span>
                </div>
                {/* menu */}
                <nav className={"main-menu menu-mobile" + (mobileMenu === true ? " show" : '')} id="menu">
                  {/* nav items*/}
                  {buildNav(nav, activeNav)}
                </nav>
                {/* menu end */}
                {/* header_extra */}
                <div className="header_extra d-flex flex-row align-items-center justify-content-end">
                  <div className="header_search d-flex">
                    <a
                      href="gre-coaching.html#"
                      className="btn-default search_btn"
                    >
                      <i className="fa fa-search" />
                    </a>
                    <div className="header_search_content">
                      <div className="header_search_content_inner">
                        <form
                          id="searchbox"
                          method="get"
                          action="gre-coaching.html#"
                        >
                          <input
                            className="search_query"
                            type="text"
                            id="search_query_top"
                            name="s"
                            placeholder="Search Here..."
                            defaultValue=""
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="header_btn">
                    <Link to="/" className="prt-btn prt-btn-size-sm prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor">
                      Home
                    </Link>
                  </div>
                </div>
                {/* header_extra end */}
              </div>
              {/* site-navigation end*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteNavbar