import React from 'react'

const Sidebar = () => {
  return (
    <aside className="widget widget-categories with-title">
      <h3 className="widget-title">Categories</h3>
      <ul>
        <li>
          <a href="services-1.html">Abroad Study</a>
        </li>
        <li>
          <a href="services-1.html">PR Applicants</a>
        </li>
        <li>
          <a href="services-1.html">Travel Insurance</a>
        </li>
        <li>
          <a href="services-1.html">Work Permits</a>
        </li>
      </ul>
    </aside>

  )
}

export default Sidebar