import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isInitDataLoad: true,
  isSending: false,
  isSuccess: false,
  isFailure: false,
  isShowErrowModal: false,
  errors: {},
  editLink: "",
  printLink: "",
}

const axiosSlice = createSlice({
  name: 'axiosSlice',
  initialState,
  reducers: {
    setInitDataLoad: (state, action) => {
      state.isInitDataLoad = action.payload
    },
    setSending: (state, action) => {
      state.isSending = action.payload
    },
    setSuccess: (state, action) => {
      state.isSuccess = action.payload
    },
    setFailure: (state, action) => {
      state.isFailure = action.payload
    },
    setErrors: (state, action) => {
      state.errors = action.payload
    },
    setShowErrowModal: (state, action) => {
      state.isShowErrowModal = action.payload
    },
    setEditLink: (state, action) => {
      state.editLink = action.payload
    },
    setPrintLink: (state, action) => {
      state.printLink = action.payload
    },
  },
})

export const {
  setInitDataLoad,
  setSending,
  setSuccess,
  setFailure,
  setErrors,
  setShowErrowModal,
  setEditLink,
  setPrintLink,
} = axiosSlice.actions

export const axiosActions = {
  setInitDataLoad,
  setSending,
  setSuccess,
  setFailure,
  setErrors,
  setShowErrowModal,
  setEditLink,
  setPrintLink,
}

export default axiosSlice.reducer