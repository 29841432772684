const handlApiError = error => {

  var returnArray = {};

  if (!error.response) {
    returnArray = {
      errorCode: "script",
      errors: [error]
    }
    return returnArray;
  }

  const { status } = error.response;
  switch (status) {
    // handle it from auth-http
    case 401: break;
    case 500:
      returnArray = {
        errorCode: status,
        errors: [error.response.data.message]
      }
      break;
    case 404:
      returnArray = {
        errorCode: status,
        errors: [`File ${error.response.config.url} not found.`]
      }
      break;
    case 422:
      const errors = error.response.data.errors;
      const errorItems = Object.entries(errors).map(([field, messages]) => (
        // <li key={field}>{messages.join(', ')}</li>
        messages.join(', ')
      ))
      returnArray = {
        errorCode: status,
        errors: [...errorItems],
      }
      break;
    case 501:
      returnArray = {
        errorCode: status,
        errors: [error.response.data.errors]
      }
      break;
    case 405:
    case 419:
      returnArray = {
        errorCode: status,
        errors: [error.response.statusText]
      }
      break;
    case 403:
      returnArray = {
        errorCode: status,
        errors: ["Sorry, you need permission to do this action."]
      }
      break;
    default:
      returnArray = {
        errorCode: status,
        errors: ["Unknown error"]
      }
      break;
  }

  return returnArray
}

export default handlApiError
