import { TextField, Autocomplete, Paper, Box, createTheme, ThemeProvider, FormControl } from '@mui/material'
import { Controller } from 'react-hook-form'
import { fontNoto } from 'core/constants'
import { useRef, useEffect } from 'react'

const filterOptions = (options, params) => {
  const { inputValue } = params
  const normalizedInputValue = inputValue.replace(/\s+/g, ' ')
  const searchTerms = normalizedInputValue.split(' ')

  return options.filter(option => {
    const label = Object.values(option).join('').toLowerCase()
    return searchTerms.every(term => label.includes(term))
  })
}

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          fontFamily: fontNoto,
          fontSize: 15,
          lineHeight: "2.1em !important",
        },
        listbox: {
          fontFamily: fontNoto,
          fontSize: 15,
        },
      },
    },
  },
})

const Chosen = ({ data, label, name, form, internalLabel = false, ...attr }) => {
  const { formState: { errors }, control } = form

  const inputRef = useRef(null)
  useEffect(() => {
    if (errors[name]) {
      inputRef.current.focus()
    }
  }, [errors, name])

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth margin="normal">
        {!internalLabel ? <label>{label}</label> : undefined}
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const { onChange, value } = field
            return (
              <Autocomplete
                value={
                  value ? data.find(option => {
                    return value === option.id
                  }) ?? null : null
                }
                filterOptions={filterOptions}
                getOptionLabel={option => {
                  return option.ar_name
                }}
                onChange={(event, newValue) => {
                  onChange(newValue ? newValue.id : null)
                }}
                options={data}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}>
                    {option.ar_name}
                  </Box>
                )}
                PaperComponent={({ children }) => (
                  <Paper style={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, .3)' }}>
                    {children}
                  </Paper>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={inputRef}
                    label={internalLabel ? label : undefined}
                    error={!!errors[name]}
                    helperText={errors[name]?.message}
                  />
                )}
              />
            )
          }}
        />
      </FormControl>
    </ThemeProvider >
  )
}

export default Chosen