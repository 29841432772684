import { Button } from '@mui/material'
import SaveIcon from "@mui/icons-material/Save"
import { styled } from '@mui/material/styles'

const ButtonStyled = styled(Button)({
  padding: "4px 22px",
  borderRadius: "15px",
})

const SaveButton = () => {
  return (
    <ButtonStyled
      size='large'
      variant='contained'
      startIcon={<SaveIcon sx={{ fontSize: "20px !important" }} />}
      type='submit'
    >
      Save
    </ButtonStyled>
  )
}

export default SaveButton