import { Dialog, DialogActions, DialogContent, DialogTitle, Grid }
  from '@mui/material'
import { Button, IconButton }
  from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { useForm } from 'react-hook-form'
import { Date, NumInput, Chosen, TextInput } from 'components/tools'
import { useStateContext } from './ContextProvider'

const Modal = ({ open }) => {

  const form = useForm({
    defaultValues: {
      from_date: "2024-02-01",
      currency_id: 1,
    }
  })
  const { handleSubmit } = form

  const { apiData, onSubmit, setFilterModalOpen } = useStateContext()

  const handleModalClose = () => {
    setFilterModalOpen(false)
  }
  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      scroll="body"
      draggable={true}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      maxWidth="lg"
      sx={{ '& .MuiDialogContent-root': { minHeight: '70vh' } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Filter
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleModalClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={false}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 4, sm: 4, md: 4 }} spacing={2}>
            <Grid item md={4} xs={12}>
              <Date
                name="from_date"
                label="From date"
                form={form}
                internalLabel
              />
              <Date
                name="to_date"
                label="To date"
                form={form}
                internalLabel
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Chosen
                name="debit_ledger"
                label="Debit ledger"
                form={form}
                data={apiData.ledgers}
                internalLabel
              />
              <Chosen
                name="credit_ledger"
                label="Credit ledger"
                form={form}
                data={apiData.ledgers}
                internalLabel
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Chosen
                name="currency_id"
                label="Currency"
                form={form}
                data={apiData.currencies}
                internalLabel
              />
              <NumInput
                name="amount"
                label="Amount"
                form={form}
                internalLabel
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextInput
                name="notes"
                label="Description"
                form={form}
                internalLabel
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleModalClose}>Cancel</Button>
          <Button type="submit" variant="contained" startIcon={<SearchIcon />} onClick={handleModalClose}>Search</Button>
        </DialogActions>
      </form>
    </Dialog >
  )
}

export default Modal