import {
  FormControl,
  TextField,
  ThemeProvider,
  createTheme
} from "@mui/material"
import { fontNoto } from "core/constants";

const TextInput = ({ label, name, form, internalLabel = false, ...attr }) => {

  const { register, formState: { errors } } = form
  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontFamily: fontNoto,
              fontSize: 15,
              lineHeight: "2.1em !important"
            },
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <FormControl fullWidth margin="normal">
        {!internalLabel ? <label>{label}</label> : undefined}
        <TextField
          {...attr}
          label={internalLabel ? label : undefined}
          fullWidth
          {...register(name)}
          error={!!errors[name]}
          helperText={errors[name]?.message}
        />
      </FormControl>
    </ThemeProvider >
  )
}

export default TextInput