import { TopBar, PageTitle, Footer } from "layout/files";

import ModuleCard from "./ModuleCard";

const HomePage = () => {

  return (
    <>
      {/* header */}
      <header id="masthead" className="header prt-header-style-01">
        <TopBar />
      </header>

      {/* title */}
      <PageTitle>
        <div className="page-title-heading">
          <h2 className="title">AccSys ERP</h2>
        </div>
      </PageTitle>

      {/* content */}
      <div className="container" style={{ padding: "40px" }}>
        <div className="row g-2 justify-content-md-center">
          <ModuleCard
            title="Admin"
            iconClass="admin-icon"
            link="/admin"
          />
          <ModuleCard
            title="Invoicing"
            iconClass="travel-icon"
            link="/invoice"
          />
          <ModuleCard
            title="Finance"
            iconClass="finance-icon"
            link="/finance"
          />
          <ModuleCard
            title="Accounting"
            iconClass="accounting-icon"
            link="/accounting"
          />
        </div>
      </div>

      {/* footer */}
      <Footer />
    </>
  )
}

export default HomePage;
