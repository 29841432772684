import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Link as MUILink,
  Grid,
  Box,
  TextField,
  Button,
  Avatar,
  CssBaseline,
  Typography,
  Container
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Copyright = props => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <MUILink color="inherit" component={Link} to="/">AccSys</MUILink>{' 2024.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const Reset = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData(event.currentTarget);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <Typography align="center" variant="subtitle2">
            If you forgot your password, well, then we’ll email you instructions to reset your password.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send password reset link
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <MUILink component={Link} to="/login" variant="body2">
                  Return to login
                </MUILink> */}
              </Grid>
              <Grid item>
                <MUILink component={Link} to="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </MUILink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default Reset