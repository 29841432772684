import { TopBar, PageTitle, Footer } from "layout/files";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <>
      {/* header */}
      <header id="masthead" className="header prt-header-style-01">
        <TopBar />
      </header>

      {/* title */}
      <PageTitle>
        <div className="page-title-heading">
          <h2 className="title">Page not found</h2>
        </div>
      </PageTitle>

      {/* conent */}
      <section className="error-404">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 res-991-mt-30 order-last order-lg-first">
              <div className="text-center text-lg-start">
                <div className="page-content">
                  <h2>Error</h2>
                  <h3>
                    <span>Opps!</span> Somethings gone missing
                  </h3>
                  <p>
                    Sorry, This page may has been moved,deleted or maybe you just
                    mis-typed the URL.
                  </p>
                </div>
                <div className="">
                  <Link
                    className="prt-btn prt-btn-size-md prt-btn-shape-round prt-btn-style-fill prt-btn-color-skincolor"
                    to="/"
                  >
                    Go To Home Pages
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="prt-404-img text-center text-lg-start">
                <img
                  width={701}
                  height={258}
                  className="img-fluid"
                  src="/assets/images/error.png"
                  alt="error.png"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* footer */}
      <Footer />
    </>
  )
}

export default Error404;
