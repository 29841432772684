export const PageTitle = ({ children }) => {
  return (
    <div className="prt-titlebar-wrapper prt-bg about-img">
      <div className="prt-titlebar-wrapper-bg-layer prt-bg-layer" />
      <div className="prt-titlebar-wrapper-inner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="prt-page-title-row-heading">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default PageTitle