import { TopBar, SiteNavbar, PageTitle, Footer } from "layout/files";

import { nav } from "pages/finance/layout"
import Sidebar from "./Sidebar"
import Content from "./Content"

const ActiveNav = {
  main: 'Dashboard'
}

const FinanceDashboard = () => {

  return (
    <>
      {/* header */}
      <header id="masthead" className="header prt-header-style-01">
        <TopBar />
        <SiteNavbar nav={nav} activeNav={ActiveNav}
        />
      </header>

      {/* title */}
      <PageTitle>
        <div className="page-title-heading">
          <h2 className="title">Finance</h2>
        </div>
      </PageTitle>

      {/* content */}
      <div className="site-main">
        <div className="sidebar prt-sidebar-left clearfix">
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-4 widget-area sidebar-left prtcol-bgcolor-yes prt-bg prt-left-span bg-base-grey">
                <div className="prt-col-wrapper-bg-layer prt-bg-layer" />
                <div className="layer-content">
                  {<Sidebar />}
                </div>
              </div>
              <div className="col-lg-8 content-area" style={{
                padding: "32px 15px 60px 15px"
              }}>
                {<Content />}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </>

  )
}

export default FinanceDashboard;
