import React, { useRef, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, InputAdornment, Stack, TextField, ThemeProvider, createTheme } from '@mui/material'
import { useStateContext } from './ContextProvider'
import { fontNoto } from 'core/constants'

const SearchInput = () => {
  const { data, setFilter, setShowSearch } = useStateContext()
  const inputRef = useRef(null)

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              fontFamily: fontNoto,
              fontSize: 15,
              lineHeight: "2.1em !important"
            },
          }
        }
      }
    }
  })

  const handleFilter = event => {
    const normalizedInputValue = event.target.value.replace(/\s+/g, ' ')
    const searchTerms = normalizedInputValue.split(' ')
    const result = data.filter(item => {
      const itemString = JSON.stringify(item).toLowerCase()
      return searchTerms.every((term) => itemString.includes(term))
    })
    setFilter(result)
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleKeyDown = event => {
    if (event.keyCode === 27) {
      setShowSearch(false)
      setFilter(data)
    }
  }

  return (
    <Stack sx={{ p: 2 }}>
      <ThemeProvider theme={theme}>

        <TextField
          label="Search"
          type="search"
          size="small"
          fullWidth
          inputRef={inputRef}
          onChange={handleFilter}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: "20px" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowSearch(false)}>
                  <ClearIcon sx={{ fontSize: "16px" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
    </Stack>
  )
}

export default SearchInput