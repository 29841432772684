import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Date, NumInput, Chosen, TextInput } from 'components/tools'
import { useStateContext } from "./ContextProvider"

const PanelData = () => {

  const { form, apiData } = useStateContext()
  const { getValues } = form

  return (
    <Grid container columnSpacing={{ lg: 2, md: 2 }} direction="row-reverse">
      <Grid item lg={12} sm={12}>
        <Typography variant='h4' mb={3} align='center' sx={{ fontFamily: "Roboto" }}>
          Serial: {getValues('serial')}
        </Typography>
      </Grid>
      <Grid item lg={9} sm={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant='h5' mb={2}>Data</Typography>
            <Grid container columnSpacing={2} >
              <Grid item lg={4} sm={6} xs={12}>
                <Date
                  name="date"
                  label="Date"
                  form={form}
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <TextInput
                  name="receipt"
                  label="Receipt"
                  form={form}
                />
              </Grid>
              <Grid item lg={4} sm={6} xs={12}>
                <TextInput
                  name="reference_number"
                  label="Reference number"
                  form={form}
                />
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Chosen
                  name="debit_ledger"
                  label="Debit ledger"
                  form={form}
                  data={apiData.ledgers}
                />
              </Grid>
              <Grid item lg={6} sm={6} xs={12}>
                <Chosen
                  name="credit_ledger"
                  label="Credit ledger"
                  form={form}
                  data={apiData.ledgers}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="notes"
                  label="Description"
                  form={form}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={3} sm={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant='h5' mb={2}>Amount</Typography>
            <Grid container columnSpacing={2} >
              <Grid item lg={12} sm={6} xs={12}>
                <NumInput
                  name="amount"
                  label="Amount"
                  form={form}
                />
              </Grid>
              <Grid item lg={12} sm={6} xs={12}>
                <NumInput
                  name="rate"
                  label="Rate"
                  form={form}
                />
              </Grid>
              <Grid item lg={12} sm={6} xs={12}>
                <Chosen
                  name="currency_id"
                  label="Currency"
                  form={form}
                  data={apiData.currencies}
                />
              </Grid>
              <Grid item lg={12} sm={6} xs={12}>
                <Typography mt={2} align='center' variant="h4">
                  {apiData.total}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PanelData