import { createContext, useContext, useState } from "react"

const StateContext = createContext({
  title: '', setTitle: data => { },
  data: [], setData: data => { },
  filter: [], setFilter: data => { },
  showSearch: false, setShowSearch: status => { },
  onFilterClick: status => { }, setOnFilterClick: status => { },
})

export const ContextProvider = ({ children }) => {

  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [filter, setFilter] = useState([])
  const [showSearch, setShowSearch] = useState(false)
  const [onFilterClick, setOnFilterClick] = useState(null)

  return (
    <StateContext.Provider value={{
      title, setTitle,
      data, setData,
      filter, setFilter,
      showSearch, setShowSearch,
      onFilterClick, setOnFilterClick,
    }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
