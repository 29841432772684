import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const GuestLayout = () => {

  const token = localStorage.getItem('token')
  if (token !== null) {
    return <Navigate to="/" />
  }

  return <Outlet />
}

export default GuestLayout