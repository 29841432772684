import { Link, useNavigate } from 'react-router-dom';
import {
  Link as MUILink,
  Grid,
  Box,
  Button,
  Avatar,
  CssBaseline,
  Typography,
  Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import http from "core/auth-http"
import { useForm } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput } from 'components/tools/index';
import { useState } from 'react';
import handlApiError from "core/handleApiError";
import { ErrorModal } from 'components/api-helper';
import LinerLoading from 'components/api-helper/LinerLoading';

const Copyright = props => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <MUILink color="inherit" component={Link} to="/">AccSys</MUILink>{' 2024.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [apiErrors, setApiErrors] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()

  const schema = yup.object().shape({
    name: yup.string()
      .required("Name is required"),
    email: yup.string()
      .email("Enter valid email address")
      .required("Email is required"),
    password: yup.string()
      .required("Password is required"),
    password_confirmation: yup.string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password')], 'Password not match'),
  })

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const { handleSubmit } = form

  const onSubmit = data => {
    setIsLoading(true)
    setApiErrors({})
    http.post('/register', data)
      .then(res => {
        localStorage.setItem("token", res.data.token)
        localStorage.setItem("user", JSON.stringify(res.data.user));
        navigate("/")
      })
      .catch(error => {
        setApiErrors(handlApiError(error))
        setModalOpen(true)
      }).finally(() => { setIsLoading(false) })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ErrorModal isOpen={modalOpen} onClose={setModalOpen} apiErrors={apiErrors} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box mt={8} pt={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: "relative"
          }}
        >
          <LinerLoading isOpen={isLoading} />
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
            <TextInput
              form={form}
              label="Name"
              name="name"
              internalLabel
            />
            <TextInput
              form={form}
              label="Email address"
              name="email"
              internalLabel
            />
            <TextInput
              form={form}
              label="Password"
              name="password"
              type="password"
              autoComplete="new-password"
              internalLabel
            />
            <TextInput
              form={form}
              label="Confirm Password"
              name="password_confirmation"
              type="password"
              autoComplete="new-password"
              internalLabel
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Register
            </Button>
            <Grid container>
              <Grid item xs></Grid>
              <Grid item>
                <MUILink component={Link} to="/register" variant="body2">
                  Already have an account? Sign in
                </MUILink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider >
  );
}

export default SignUp