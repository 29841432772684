import {
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { UpdateButton } from "components/tools/index"
import RefreshIcon from '@mui/icons-material/Sync'
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useState } from "react"
import SearchIcon from '@mui/icons-material/Search'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useNavigate } from "react-router-dom"

const RefreshButton = () => {
  const navigate = useNavigate()
  return (
    <Tooltip title="Refresh page">
      <IconButton size="small" onClick={() => {
        return () => navigate(0)
      }}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  )
}

const SearchButton = () => {
  const navigate = useNavigate()
  return (
    <Tooltip title="Back to search">
      <IconButton size="small" onClick={() => {
        navigate('/finance/cash_payment')
      }}>
        <SearchIcon />
      </IconButton>
    </Tooltip>
  )
}

const DeleteButton = () => {
  return (
    <Tooltip title="Delete">
      <IconButton color="error" size="small" onClick={() => {
        console.log('delete');
      }}>
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

const PrintButton = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  return (
    <>
      <Tooltip title="Print">
        <IconButton
          size="small"
          aria-label="print"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <PrintOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem dense>
          <ListItemIcon>
            <PrintOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Print</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

const ExtraMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Tooltip title="More">
        <IconButton
          size="small"
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem dense>
          <ListItemIcon>
            <AddOutlinedIcon />
          </ListItemIcon>
          <ListItemText>New document</ListItemText>
        </MenuItem>

        <MenuItem dense>
          <ListItemIcon>
            <ListAltOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Journal</ListItemText>
        </MenuItem>

        <MenuItem dense>
          <ListItemIcon>
            <EditCalendarOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Show creation</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

const Buttons = () => {
  return (
    <Stack direction="row-reverse" justifyContent="space-between" mb={3} p={2} >
      <UpdateButton />

      <Stack direction="row" justifyContent="center" spacing={1}>
        <ExtraMenu />
        <SearchButton />
        <RefreshButton />
        <DeleteButton />
        <PrintButton />
      </Stack>

    </Stack>
  )
}

export default Buttons