// @ts-nocheck
import { TopBar, SiteNavbar, PageTitle, Footer } from "layout/files"
import { nav, Title } from "pages/finance/layout"
import PanelData from "./PanelData"
import Buttons from "./Buttons"
import { useStateContext, ContextProvider } from "./ContextProvider"
import { Box } from "@mui/material"
import { ErrorModal, LinerLoading, UpdatingSnackBar } from "components/api-helper"

const ActiveNav = {
  main: 'Payments',
  sub: 'Cash payment',
}

const Index = () => {
  return (
    <ContextProvider>
      <Page />
    </ContextProvider>
  )
}

const Page = () => {
  const { form, onSubmit, isInitLoad } = useStateContext()
  const { handleSubmit } = form

  return (
    <>
      {/* header */}
      <header id="masthead" className="header prt-header-style-01">
        <TopBar />
        <SiteNavbar nav={nav} activeNav={ActiveNav} />
      </header>

      {/* title */}
      <PageTitle>
        {<Title title="Cash payment" />}
      </PageTitle>

      <ErrorModal />
      <UpdatingSnackBar />

      {/* content */}
      <Box className="container" my={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ position: "relative", minHeight: "400px" }}>
            <LinerLoading isOpen={isInitLoad} type="absolute" />
            {isInitLoad ? undefined :
              <>
                <Buttons />
                <PanelData />
              </>
            }
          </Box>
        </form>
      </Box>

      {/* footer */}
      <Footer />
    </>
  )
}

export default Index