import axios from "axios"
import { Navigate } from "react-router-dom"

const http = axios.create({
  baseURL: "http://localhost:8000/api",
  headers: {
    "Content-type": "application/json"
  },
  withCredentials: true,
})

http.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  config.headers.Authorization = `Bearer ${token}`

  return config
})

http.interceptors.response.use(
  response => { return response },
  error => {
    const { response } = error
    if (response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('user')

      return (<Navigate to="/login" />)
    }

    throw error
  }
)

export default http