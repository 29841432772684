/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { TopBar, SiteNavbar, PageTitle, Footer } from "layout/files"

import FilterTable from "components/datatable/Table"
import { nav, Title } from "pages/finance/layout"
import { Columns } from "./Data"

import Modal from "./Modal"
import { Box, Stack } from "@mui/material"
import ImportButton from "components/tools/buttons/ImportButton"
import NewRecordButton from "components/tools/buttons/NewRecordButton"

import { ErrorModal } from "components/api-helper/index"
import LinerLoading from "components/api-helper/LinerLoading"
import { ContextProvider, useStateContext } from "./ContextProvider"

const pageTitle = "Cash payment"

const activeNav = {
  main: 'Payments',
  sub: pageTitle
}

const Index = () => {
  return (
    <ContextProvider>
      <Page />
    </ContextProvider>
  )
}

const Page = () => {
  const {
    isLoading, initialData, data, apiErrors,
    errorModalOpen, setErrorModalOpen,
    filterModalOpen, setFilterModalOpen,
  } = useStateContext()

  useEffect(() => {
    initialData()
  }, [])

  const handleFilterClick = () => {
    return () => setFilterModalOpen(true)
  }

  return (
    <div className="page">
      <header id="masthead" className="header prt-header-style-01">
        <TopBar />
        <SiteNavbar nav={nav} activeNav={activeNav} />
      </header>
      <PageTitle>
        {<Title title={pageTitle} />}
      </PageTitle>

      <ErrorModal isOpen={errorModalOpen} onClose={setErrorModalOpen} apiErrors={apiErrors} />

      <div className="container">
        <div className="row g-0">
          <div className="col-lg-12 content-area" style={{
            padding: "40px 15px 60px 15px"
          }}>
            <Stack direction="row" sx={{
              justifyContent: "space-between", p: "10px 30px", mb: "25px"
            }}>
              <Stack spacing={2} direction="row">
              </Stack>
              <Stack direction="row" spacing={1}>
                <ImportButton disableElevation />
                <NewRecordButton disableElevation link="/finance/cash_payment/create" />
              </Stack>
            </Stack>
            <Box sx={{ position: "relative" }}>
              <LinerLoading
                isOpen={isLoading}
                type="absolute"
              />
              <FilterTable
                onFilterClick={handleFilterClick}
                columns={Columns}
                sortField={2}
                data={data}
                title="Cash payment"
              />
            </Box>
          </div>
        </div>
      </div>
      <Modal open={filterModalOpen} />
      <Footer />
    </div>
  )
}

export default Index
