import { Button } from "@mui/material"
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

const ImportButton = (props) => {
  return (
    <Button
      variant='outlined'
      startIcon={<ArrowDownwardOutlinedIcon />}
      size="responsive"
      {...props}
    >
      Import
    </Button>
  )
}

export default ImportButton