import React from 'react'

const Content = () => {
  return (
    <div className="row g-0 mt-50 res-991-mt-30">
      <div className="col-md-6 pr-25 res-767-pr-0">
        <div className="featured-icon-box style17">
          <div className="featured-icon">
            <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-skincolor">
              <i className="flaticon-passport-2" />
            </div>
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h3>Required form application</h3>
            </div>
            <div className="featured-desc">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum hasbeen the industry's
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 pl-25 res-767-pl-0 res-767-mt-30">
        <div className="featured-icon-box style17">
          <div className="featured-icon">
            <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-skincolor">
              <i className="flaticon-process" />
            </div>
          </div>
          <div className="featured-content">
            <div className="featured-title">
              <h3>Online passport application</h3>
            </div>
            <div className="featured-desc">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum hasbeen the industry's
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Content