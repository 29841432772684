import { useNavigate } from "react-router-dom"

const TopBar = () => {
  const navigate = useNavigate()

  const Logout = e => {
    e.preventDefault()
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    navigate("/login")
  }

  const user = JSON.parse(localStorage.getItem("user"))

  return (
    <div className="top_bar prt-topbar-wrapper text-base-white clearfix">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex flex-row align-items-start justify-content-start">
              <div className="top_bar_contact_item">
                <span className="text-base-skin">Email:</span>
                <a href="/" onClick={e => e.preventDefault()}>
                  {user && user.email}
                </a>
              </div>
              <div className="top_bar_contact_item">
                <span className="text-base-skin">Call Now:</span>
                <a href="tel:000-723-123-21">+000 723 123 21</a>
              </div>
              <div className="top_bar_contact_item top_bar_social ms-auto">
                <ul className="social-icons">
                  <li className="prt-social-twitter">
                    <a href="/" onClick={e => Logout(e)}>
                      <i className="icon-linkedin" />
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar