import { Link } from "react-router-dom";
import {
  Button,
  useMediaQuery,
  useTheme
} from "@mui/material"
import AddIcon from '@mui/icons-material/Add';

const IconBtn = ({ props }) => {
  return (
    <Button
      variant="contained"
      sx={{
        bgcolor: '#1D5498',
        minWidth: "10px !important",
        padding: "6px",
        borderRadius: 0,
        '& .MuiSvgIcon-root': {
          // fontSize: "24px",
          color: 'white',
        },
      }}
    >
      <AddIcon />
    </Button>
  );
}

const FullBtn = ({ props }) => {
  return (
    <Button
      variant='contained'
      startIcon={<AddIcon />}
      {...props}
    >
      New record
    </Button>
  )
}

const NewRecordButton = (props) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Link to={props.link}>
      {
        isMatch ?
          <IconBtn props={props} /> :
          <FullBtn props={props} />
      }

    </Link>
  )


}

export default NewRecordButton