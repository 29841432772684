import { Link } from 'react-router-dom'

export const nav = [
  {
    text: "Dashboard",
    link: "/finance",
    subMenu: []
  },
  {
    text: "Payments",
    link: "",
    subMenu: [
      {
        text: "Cash payment",
        link: "/finance/cash_payment",
      },
      {
        text: "Currency exchange",
        link: "/finance/currency_exchange",
      },
      {
        text: "Treasure exchange",
        link: "/finance/treasure_exchange",
      },
      {
        text: "Journal entry",
        link: "/finance/journal_entry",
      },
      {
        text: "Salary",
        link: "/finance/salary",
      },
    ]
  },
  {
    text: "Cheques",
    link: "",
    subMenu: [
      {
        text: "Pay cheque",
        link: "/finance/pay_cheque",
      },
      {
        text: "Receive cheque",
        link: "/finance/receive_cheque",
      }
    ]
  },
  {
    text: "Settings",
    link: "",
    subMenu: [
      {
        text: "Chart of accounts",
        link: "/finance/ledgers",
      },
      {
        text: "Currencires",
        link: "/finance/currencies",
      },
      {
        text: "Payments methods",
        link: "/finance/payments_methods",
      },
      {
        text: "Treasury",
        link: "/finance/treasury",
      },
    ]
  },
]

export const Title = ({ title }) => {
  return (
    <>
      <div className="page-title-heading">
        <h2 className="title">{title}</h2>
      </div>
      <div className="breadcrumb-wrapper">
        <i className="flaticon-home" />
        <span>
          <Link title="Homepage" to="/">
            Home
          </Link>
        </span>
        <div className="prt-sep"> - </div>
        <span>
          <Link title="Finance" to="/finance">
            Finance
          </Link>
        </span>
      </div>
    </>
  )
}